<template>
    <div>
        <loading v-if="!isMeetingInitialized" />

        <div id="zoom-client-element" />

        <!-- <heat-map
            v-if="cIsUserAdmin"
            class="heat-map"
        /> -->
    </div>
</template>

<script>
// https://socket.io/docs/v4/client-api/
import { io } from 'socket.io-client'

import { ZoomSignatureController } from '../controllers/ZoomSignatureController'
import { ZoomMtgController } from '../controllers/ZoomMtgController'
import { WatermarkController } from '../controllers/WatermarkController'
import { IpAddressController } from '../controllers/IpAddressController'
import { UserDataController } from '../controllers/UserDataController'

import { JoinedMeetingEvent } from '../events/JoinedMeetingEvent'

import Loading from './Loading.vue'
// import HeatMap from './HeatMap.vue'

export default {
    components: {
        Loading,
        // HeatMap
    },
    name: 'LaiZoomClient',

    props: {
        token: {
            type: String,
            default: ''
        },

        userId: {
            type: Number,
            default: 0
        },

        courseId: {
            type: Number,
            default: 0
        },

        liveClassId: {
            type: Number,
            default: 0
        },

        leaveUrl: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            appApiUrl: process.env.VUE_APP_API_URL,
            sockerUrl: process.env.VUE_APP_SOCKET_URL,
            zoomVersion: process.env.VUE_APP_ZOOM_VERSION,

            socket: {},

            userRoles: ['STUDENT'],
            tc: '',
            ipAddress: '',

            isDependeciesLoaded: false,
            isMeetingInitialized: false,
        }
    },

    computed: {
        cIsUserHost () {
            return this.cIsUserAdmin || this.cIsUserTeacher
        },

        cIsUserAdmin () {
            return this.userRoles.includes('ADMIN')
        },

        cIsUserTeacher () {
            return this.userRoles.includes('TEACHER')
        },

        cIsAllowedToBoot () {
            return this.token && this.courseId > 0 && this.liveClassId > 0 && this.userId && this.leaveUrl
        },

        cSignatureApi () {
            return `${this.appApiUrl}/v2/courses/${this.courseId}/live-classes/${this.liveClassId}/${this.cIsUserHost ? 'teacher-signature' : 'signature'}/`
        }
    },

    watch: {
        cIsAllowedToBoot (newValue) {
            if (newValue) {
                this.bootApp()
            }
        }
    },

    methods: {
        initSocketServer () {
            this.socket = io(this.sockerUrl, {
                // auht, query ...
            })
        },

        async bootApp () {
            if (!this.isDependeciesLoaded) {
                await this.loadDependencies()
            }

            if (!this.isMeetingInitialized) {
                this.startApp()
            }
        },

        async loadDependencies () {
            window.ZoomMtg.setZoomJSLib(`https://source.zoom.us/${this.zoomVersion}/lib`, '/av')
            await window.ZoomMtg.preLoadWasm()
            await window.ZoomMtg.prepareWebSDK()

            // kendi türkçe dil objemiz "/lang/tr-TR.json" bu dosya üzerinde düzenleme yapılabilir.
            // Must call ZoomMtg.i18n.load before ZoomMtg.init to make internationalization and localization (i18n) work.
            await window.ZoomMtg.i18n.load("/lang/tr-TR.json", "tr-TR")

            this.isDependeciesLoaded = true
        },

        async startApp () {
            if (this.cIsAllowedToBoot) {
                await this.fetchUserData()

                this.bootZoomClient()
                

                document.addEventListener(JoinedMeetingEvent.name, () => {
                    this.isMeetingInitialized = true
                    
                    if (this.cIsUserTeacher) {
                        this.informStudents()
                    }
                    if (!this.cIsUserHost) {
                        this.setWatermarks()
                    }
                })
            }
        },

        async bootZoomClient () {
            const signatureController = new ZoomSignatureController(this.cSignatureApi, this.token)
            await signatureController.getSignature()
            
            const mtgController = new ZoomMtgController(this.leaveUrl)
            if (this.cIsUserHost) {
                mtgController.authorizeForHost(this.cIsUserTeacher)
            } else {
                mtgController.authorizeForAttendee()
            }
            mtgController.initMeeting(signatureController.getMeetingConfigs())
        },

        informStudents () {
            // ögrencileri de egitime katabilmek icin app icerisinde buton gösterelim
            this.socket.emit('initiate-course', this.courseId)
        },
        
        async fetchUserData () {
            const ipAddressController = new IpAddressController(this.appApiUrl)
            await ipAddressController.fetchFromAPi()
            this.ipAddress = ipAddressController.getIpAddress()

            const userDataController = new UserDataController(this.appApiUrl, this.userId, this.token)
            await userDataController.fetchFromAPi()
            this.tc = userDataController.getTc()
            this.userRoles = userDataController.getUserRoles()
        },

        setWatermarks () {
            const watermarkController = new WatermarkController()
            watermarkController.setWatermarks([
                {
                    text: this.tc,
                },
                {
                    text: this.ipAddress,
                    style: {
                        top: '50px',
                    }
                }
            ])
        },
    },

    async mounted () {
        this.initSocketServer()
        
        this.bootApp()
    },

    beforeDestroy () {
        clearInterval(this.joinAudioInterval)
        this.joinAudioInterval = null
    },
}
</script>

<style>
#zmmtg-root {
    display: none;
}

#zoom-client-element {
    pointer-events: none;
    z-index: 999999999999;
    background-color: transparent;
}

/* footer butonların soldaki ilk bölümü. kamera ve mikrofon aç-kapat */
html:not(.meeting-host) footer#wc-footer > .footer__inner > div:first-child > * {
    display: none !important;
}

/* ekranın sol üst köşesindeki görüşme hakkında bilgilendirme ve görüşmenin kaydı ile ilgili pause stop vb. butonlar */
.meeting-info-container.meeting-info-container--left-side {
    display: none !important;
}

/* Ekran genişliği daraldığında alttaki butonlar Daha Fazla isimli bir buton altına giriyor. */
html:not(.meeting-host) .footer__inner .more-button {
    display: none !important;
}

/* Ekranın alt kısmındaki "Güvenlik" etiketli buton */
html:not(.meeting-host) .security-option-menu {
    display: none !important;
}

/* Ekranın alt kısmındaki "Katılımcılar" etiketli buton */
html.meeting-teacher [feature-type*="participants"],
html:not(.meeting-host) [feature-type*="participants"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Anketler" etiketli buton */
html:not(.meeting-host) [feature-type*="Poll"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Ekran Paylaş" etiketli buton */
html:not(.meeting-host) [feature-type*="sharing"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Pause/Stop Recording" etiketli butonlar */
html:not(.meeting-host) [feature-type*="record"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "AI Companion" etiketli buton */
html:not(.meeting-host) [feature-type*="AICompanion"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Reactions" etiketli buton */
html:not(.meeting-host) [feature-type*="reaction"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Diger" etiketli buton */
html:not(.meeting-host) [feature-type*="more"] {
    display: none !important;
}

/* Ekranın alt kısmındaki "Altyazilar" etiketli buton */
html:not(.meeting-host) [feature-type*="newLTT"] {
    display: none !important;
}

/* notification manager */
html:not(.meeting-host) #notificationManager {
    display: none !important;
}

/* kullanici chat kisminda diger kisilerin isimlerini görmesin */
html:not(.meeting-host) section.chat-receiver-list__scrollbar {
    display: none;
}

.heat-map {
    position: absolute;
    top: 64px;
    left: 16px;
    width: 100%;
    height: 100%;
    z-index: 9999999999999;
    pointer-events: none;
}
</style>
