export class HeatMapUserCountChangedEvent extends CustomEvent {
    static name = 'heatMapUserCountChanged'

    /**
     * @param {Array<Number, Number>} userCountMap 
     */
    constructor(userCountMap) {
        super(HeatMapUserCountChangedEvent.name, {
            bubbles: true,
            cancelable: true,
            detail: { userCountMap }
        })
    }
}