import { HeatMapUserCountChangedEvent } from "../events/HeatMapUserCountChangedEvent"

export class MeetingHeatMap {
    static #instance
    #userTimeMap = new Map()

    constructor() {
        if (MeetingHeatMap.#instance) {
            throw new Error('Cannot create multiple instances of MeetingHeatMap')
        }
    }

    static getInstance() {
        if (!MeetingHeatMap.#instance) {
            MeetingHeatMap.#instance = new MeetingHeatMap()
        }
        
        return MeetingHeatMap.#instance
    }

    increaseUserCount() {
        if (this.hasGapBetweenLastMinuteAndCurrentMinute()) {
            this.fillGapBetweenLastMinuteAndCurrentMinute()
        }

        const userCountAtCurrentMinute = this.getUserCountAtCurrentMinute()
        this.#userTimeMap.set(this.getCurrentMinute(), userCountAtCurrentMinute + 1)

        document.dispatchEvent(new HeatMapUserCountChangedEvent(this.#userTimeMap))
    }

    decreaseUserCount() {
        if (this.hasGapBetweenLastMinuteAndCurrentMinute()) {
            this.fillGapBetweenLastMinuteAndCurrentMinute()
        }

        const userCountAtMinute = this.getUserCountAtCurrentMinute()
        if (userCountAtMinute > 0) {
            this.#userTimeMap.set(this.getCurrentMinute(), userCountAtMinute - 1)

            document.dispatchEvent(new HeatMapUserCountChangedEvent(this.#userTimeMap))
        }
    }

    getCurrentMinute() {
        const uniqueTimestampInSeconds = Math.floor(Date.now() / 1000)
        return Math.floor(uniqueTimestampInSeconds / 60)
    }

    getLastMinuteOfMap() {
        return Array.from(this.#userTimeMap.keys()).pop()
    }

    hasGapBetweenLastMinuteAndCurrentMinute() {
        return this.getCurrentMinute() - this.getLastMinuteOfMap() > 1
    }

    getUserCountAtMinute(minute) {
        return this.#userTimeMap.get(minute) || 0
    }

    getUserCountAtCurrentMinute() {
        return this.getUserCountAtMinute(this.getCurrentMinute())
    }

    fillGapBetweenLastMinuteAndCurrentMinute() {
        const lastMinute = this.getLastMinuteOfMap()
        for (let minute = lastMinute + 1; minute < this.getCurrentMinute(); minute++) {
            this.#userTimeMap.set(minute, this.getUserCountAtMinute(lastMinute))
        }
    }
}