import { JoinedMeetingEvent } from "../events/JoinedMeetingEvent"
import { MeetingHeatMap } from "./MeetingHeatMap"

export class ZoomMtgController {
    #meeting
    #config = {}
    #leaveUrl
    #isHost
    #isTeacher

    #meetingHeatMap = MeetingHeatMap.getInstance()

    constructor(leaveUrl) {
        this.#meeting = window.ZoomMtg
        this.#leaveUrl = leaveUrl
    }

    authorizeForHost(isTeacher = true) {
        this.#isHost = true
        this.#isTeacher = isTeacher

        this.#config = {
            disablePictureInPicture: true,
            isSupportAV: true,
            leaveUrl: this.#leaveUrl,
        }
    }

    authorizeForAttendee() {
        this.#isHost = false

        this.#config = {
            debug: false, //optional
            audioPanelAlwaysOpen: false, //optional
            disableCallOut: true, //optional
            // disableCORP: true, // optional
            disableInvite: true, //optional
            disableJoinAudio: false, //optional
            disablePictureInPicture: true,
            disablePreview: true, // optional
            disableRecord: true, //optional
            disableReport: true, // optional
            disableVoIP: false, // optional
            // inviteUrlFormat: '', // optional
            // isLockBottom: true, // optional,
            // isShowJoiningErrorDialog: true, // optional,
            isSupportAV: true, //optional,
            // isSupportBreakout: true, //optional
            // isSupportCC: false, //optional,
            isSupportNonverbal: false, // optional,
            isSupportPolling: true, //optional
            // isSupportQA: true, //optional,
            // leaveUrl: 'http://www.zoom.us', //required
            leaveUrl: this.#leaveUrl,
            // loginWindow: {  // optional,
            //     width: 400,
            //     height: 380
            // },
            // meetingInfo: [ // optional
            //     'topic',
            //     'host',
            //     'mn',
            //     'pwd',
            //     'telPwd',
            //     'invite',
            //     'participant',
            //     'dc',
            //     'enctype',
            //     'report'
            // ],
            // rwcBackup: '', //optional,
            screenShare: false, //optional,
            // sharingMode: 'both', //optional,
            showMeetingHeader: false, //option
            showPureSharingContent: true, //optional
            videoDrag: false, //optional,
            videoHeader: false, //optional,
            // webEndpoint: 'PSO web domain', // PSO option
        }
    }

    initMeeting(configs = {}) {
        this.#showUI()

        this.#meeting.init({
            ...this.#config,
            success: () => {
                this.joinMeeting(configs)
            },
            error: (error) => {
                console.log(error)
            }
        })

        this.#meeting.inMeetingServiceListener('onUserJoin', () => {
            this.#meetingHeatMap.increaseUserCount()
        })

        this.#meeting.inMeetingServiceListener('onUserLeave', () => {
            this.#meetingHeatMap.decreaseUserCount()
        })

        window.ZoomMtg.inMeetingServiceListener('onMeetingStatus', (data) => {
            // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
            // console.log('inMeetingServiceListener onMeetingStatus', data)
            // 2 => meeting baslatildi
            if (data?.meetingStatus === 2) {
                // http requestte başka bir sorun olabileceği gerekçesiyle sorgudan önce event fırlatmak
                window.top.postMessage('meetingConnected', '*')

                window.top.postMessage('meetingConnectedRequest', '*')
            }
        })
    }

    #showUI() {
        // todo buraya neden ihtiyac duyduk?
        // ZoomClient.vue component icerisinde css ile ilk basta display none yapilmis
        document.getElementById('zmmtg-root').style.display = 'block'
    }

    joinMeeting(configs = {}) {
        this.#meeting.join({
            ...configs,
            success: () => {
                if (!this.#isHost) {
                    this.#meeting.showJoinAudioFunction({show: false})
                }

                document.dispatchEvent(new JoinedMeetingEvent())

                this.configureCustomUI()
            },
            error: (error) => {
                console.log(error)
            }
        })
    }

    configureCustomUI() {
        if (this.#isHost) {
            this.setHtmlClassesForHost()
            return
        }

        this.setChatConfig()
        
        const activateAudioTimout = setTimeout(() => {
            this.activateAudio()
            clearInterval(activateAudioTimout)
        }, 5000)
    }

    setHtmlClassesForHost() {
        const htmlElement = document.querySelector('html')
        const htmlElementClasses = htmlElement.classList
        
        htmlElementClasses.add('meeting-host')
        
        if (this.#isTeacher) {
            htmlElementClasses.add('meeting-teacher')
        }
    }

    setChatConfig() {
        let isChatOpened = false

        const setChatConfigInterval = setInterval(() => {
            const chatButton = document.querySelector("[aria-label*='chat']")

            const onChatOpenedForChatButton = () => {
                const onChatOpenedForChatButtonInterval = setInterval(() => {
                    if (document.querySelector(".chat-container.window-content-bottom")) {
                        isChatOpened = true
                        chatButton.removeEventListener("click", onChatOpenedForChatButton)
                        clearInterval(onChatOpenedForChatButtonInterval)
                    }
                }, 1000)
            }

            if (!isChatOpened && chatButton) {
                chatButton.addEventListener("click", onChatOpenedForChatButton)
                chatButton.click()
            }

            if (document.querySelector("#chatReceiverMenu")) {
                const chatReceiverMenu = document.querySelector('#chatReceiverMenu')
                chatReceiverMenu.querySelector('.chat-receiver-list__receiverName')?.click()

                const menuItems = chatReceiverMenu.querySelectorAll('.chat-receiver-list__menu-item')

                menuItems.forEach(item => {
                    // Eğer item içinde chat-receiver-list__appendix yoksa, elementi gizle
                    // Sadece oturum sahibinde chat-receiver-list__appendix var.
                    if (!item.querySelector('.chat-receiver-list__appendix')) {
                        item.style.display = 'none'
                    } else {
                        // oturum sahibini secelim chat yapilabileceklerden
                        item.click()
                    }
                })
                if (menuItems.length > 0) {
                    clearInterval(setChatConfigInterval)
                }
            }
        }, 1000)
    }

    activateAudio() {
        const joinAudioInterval = setInterval(() => {
            const joinAudioBtn = document.querySelector('.join-audio-container__btn[aria-label="join audio"]')
            if (joinAudioBtn) {
                // 'unmute my microphone' 'mute my microphone' 'join audio'
                joinAudioBtn.click()
                clearInterval(joinAudioInterval)
            }
        }, 1000)
    }
}