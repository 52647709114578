import axios from "axios"

export class IpAddressController {
    #apiUrl
    #ipAddress

    constructor(from) {
        this.#apiUrl = `${from}/v1/get-ip-address`
    }

    async fetchFromAPi() {
        try {
            const response = await axios.get(this.#apiUrl)
            this.#ipAddress = response.data.ip
        } catch (error) {
            console.log('error', error)
        }
    }

    getIpAddress() {
        return this.#ipAddress
    }
}