import axios from "axios"

export class UserDataController {
    #apiUrl
    #token
    #tc
    #userRoles

    constructor(from, userId, token) {
        this.#apiUrl = `${from}/v1/users/${userId}`
        this.#token = token
    }

    async fetchFromAPi() {
        try {
            const response = await axios.get(this.#apiUrl, {
                    headers: {
                        'Authorization': `Token ${this.#token}`
                    }
                })
            this.#tc = response.data.tc_kimlik_number
            this.#userRoles = response.data.auth_role
        } catch (error) {
            console.log('error', error)
        }
    }

    getTc() {
        return this.#tc
    }

    getUserRoles() {
        return this.#userRoles
    }
}